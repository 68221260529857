<template>
  <dot-pad320-key01 ref="dot-pad320-key01"
                    @twenty-cells-left-panning="twentyCellsLeftPanning"
                    @twenty-cells-right-panning="twentyCellsRightPanning"></dot-pad320-key01>
  <dot-pad320-key02 ref="dot-pad320-key02"
                    @twenty-cells-left-panning="twentyCellsLeftPanning"
                    @twenty-cells-right-panning="twentyCellsRightPanning"
                    @previous-page="previousPage"
                    @next-page="nextPage"
                    @refresh="refresh"
                    @select-pen-tool="selectPenTool"
                    @select-eraser-all-tool="selectEraserAllTool"
                    @add-page="addPage"
                    @delete-page="deletePage"></dot-pad320-key02>
</template>

<script>
import {DotpadList} from "@/components/dtms-editor-v1.7/js/DotpadList";
import {defineAsyncComponent} from "vue";

const DotPad320Key01 = defineAsyncComponent(() => import ("@/views/canvas/dot-pad/DotPad320Key-0.1.vue"));
const DotPad320Key02 = defineAsyncComponent(() => import ("@/views/canvas/dot-pad/DotPad320Key-0.2.vue"));

export default {
  name: "DotPad320Key",
  components: {
    DotPad320Key01,
    DotPad320Key02,
  },
  emits: ['twentyCellsLeftPanning', 'twentyCellsRightPanning', 'previousPage', 'nextPage', 'refresh', 'selectPenTool', 'selectEraserAllTool', 'addPage', 'deletePage'],
  methods: {
    keyEventCallback(dotPad, key) {
      const dotPadList = DotpadList.getList();
      const firmwareVersion = dotPad.deviceInfo.firmwareVersion;
      const versionArray = firmwareVersion?.split(".");
      let version;
      let callbackFunction;

      if (versionArray?.length >= 3) {
        version = parseFloat(`${versionArray[1]}.${versionArray[2]}`);
      }

      if (firmwareVersion && version >= 0.2) {
        callbackFunction = dotPadList.length > 1 ? this.$refs["dot-pad320-key02"].onMultipleConnection : this.$refs["dot-pad320-key02"].onSingleConnection;
      } else {
        callbackFunction = dotPadList.length > 1 ? this.$refs["dot-pad320-key01"].onMultipleConnection : this.$refs["dot-pad320-key01"].onSingleConnection;
      }

      return callbackFunction(dotPad, key);
    },
    twentyCellsLeftPanning(dotPad) {
      this.$emit('twentyCellsLeftPanning', dotPad);
    },
    twentyCellsRightPanning(dotPad) {
      this.$emit('twentyCellsRightPanning', dotPad);
    },
    previousPage() {
      this.$emit('previousPage');
    },
    nextPage() {
      this.$emit('nextPage');
    },
    refresh(dotPad) {
      this.$emit('refresh', dotPad);
    },
    selectPenTool() {
      this.$emit('selectPenTool');
    },
    selectEraserAllTool(dotPad) {
      this.$emit('selectEraserAllTool', dotPad);
    },
    addPage() {
      this.$emit('addPage');
    },
    deletePage() {
      this.$emit('deletePage');
    },
  },
}

</script>
